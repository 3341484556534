import { FC, Fragment } from 'react';
import { HeadInner } from '../../components/Head';
import { Layout } from '../../components/layout';
import { css } from '@emotion/react';
import { colors } from '../../theme/colors';
import { Link } from 'gatsby';
import { PaildButton } from '../../components/atoms/PaildButton';
import { StaticImage } from 'gatsby-plugin-image';

export const Head = () => (
  <HeadInner
    path='/price/'
    title='料金'
    description='paild（ペイルド）の料金ページです。初期費用のみでも十分便利にご活用いただけるサービスではございますが、オプションをご利用いただくことで、さらに利便性が高まります。料金の詳細はお気軽にお問い合わせください。'
  />
);

type FunctionStatus = {
  status: 'ok' | 'middle' | 'bad' | 'none';
  caption?: string;
};

type TableContents = {
  title: string;
  basic: FunctionStatus;
  option: FunctionStatus;
};

type TableContentsWithTitle = {
  title: string;
  contents: TableContents[];
};

const tableContentsWithTitles: TableContentsWithTitle[] = [
  {
    title: 'カード機能',
    contents: [
      {
        title: 'バーチャルカード',
        basic: { status: 'ok' },
        option: {
          status: 'none',
        },
      },
      {
        title: 'リアルカード',
        basic: { status: 'middle', caption: '枚数制限あり' },
        option: {
          status: 'ok',
          caption: '枚数制限なし',
        },
      },
      {
        title: '8種類もの利用制限',
        basic: { status: 'ok' },
        option: {
          status: 'none',
        },
      },
      {
        title: 'ワンクリックで利用停止',
        basic: { status: 'ok' },
        option: {
          status: 'none',
        },
      },
    ],
  },
  {
    title: '通知機能',
    contents: [
      {
        title: '利用可能額が減少した場合',
        basic: { status: 'ok' },
        option: {
          status: 'none',
        },
      },
      {
        title: '決済された場合',
        basic: { status: 'ok' },
        option: {
          status: 'none',
        },
      },
      {
        title: 'メール経由で領収書の提出',
        basic: { status: 'ok' },
        option: {
          status: 'none',
        },
      },
      {
        title: '領収書の提出漏れを催促する場合',
        basic: { status: 'bad' },
        option: {
          status: 'ok',
        },
      },
      {
        title: '仕訳の登録漏れを催促する場合',
        basic: { status: 'bad' },
        option: {
          status: 'ok',
        },
      },
    ],
  },
  {
    title: '領収書管理',
    contents: [
      {
        title: 'アップロード容量',
        basic: { status: 'ok', caption: '無制限' },
        option: {
          status: 'none',
        },
      },
      {
        title: 'データバックアップ',
        basic: { status: 'ok' },
        option: {
          status: 'none',
        },
      },
      {
        title: '電子帳簿保存法への対応',
        basic: { status: 'ok' },
        option: {
          status: 'none',
        },
      },
    ],
  },
  {
    title: '仕訳',
    contents: [
      {
        title: '仕訳入力',
        basic: { status: 'ok' },
        option: {
          status: 'none',
        },
      },
      {
        title: 'AI-OCR\n（適格事業者番号・日付・金額）',
        basic: { status: 'ok' },
        option: {
          status: 'none',
        },
      },
      {
        title: '適格事業者番号の判定',
        basic: { status: 'ok' },
        option: {
          status: 'none',
        },
      },
      {
        title: 'インボイス制度への対応',
        basic: { status: 'ok' },
        option: {
          status: 'none',
        },
      },
    ],
  },
  {
    title: '会計ソフト連携⁨⁩⁨⁩（ERP含む）',
    contents: [
      {
        title: 'ご利用の会計ソフトに合わせた\nCSVフルカスタマイズ',
        basic: { status: 'ok' },
        option: {
          status: 'ok',
          caption: '設定代行',
        },
      },
    ],
  },
];

const PricePage: FC = () => {
  const links = [{ name: 'paildの機能', path: '/feature/' }];
  return (
    <Layout links={links}>
      <div css={TopSectionBackgroundStyle}>
        <div
          css={{
            position: 'relative',
            width: '100%',
            height: '100%',
          }}
        >
          <StaticImage
            src='../../images/price/background1.svg'
            alt='background1'
            placeholder='none'
            objectFit='contain'
            css={{
              position: 'absolute',
              top: '1000px',
              left: 0,
              width: '20vw',
              zIndex: 2,
            }}
          />
          <StaticImage
            src='../../images/price/background2.svg'
            alt='background2'
            placeholder='none'
            objectFit='contain'
            css={{
              position: 'absolute',
              top: '2300px',
              right: 0,
              width: '20vw',
              zIndex: 2,
            }}
          />
        </div>
      </div>
      <div css={ArticleListHeadingStyle}>
        <div css={ArticleListHeadingWrapperStyle}>
          <div css={TextWrapperStyle}>
            <p css={CategoryStyle}>price</p>
            <h2 css={TitleStyle}>料金</h2>
          </div>
          <div css={CardWrapperStyle}>
            <div css={CardStyle}>
              <div css={CardTitleStyle}>初期費用</div>
              <div css={CardDescriptionStyle}>
                <p css={PriceStyle}>
                  100,000円<span>(税抜)</span>
                </p>
                <p>
                  カード発行、領収書管理、仕訳入力など、paildの基本機能をご利用いただけます。
                </p>
              </div>
            </div>
            <div css={CardStyle}>
              <div css={CardTitleStyle}>オプション</div>
              <div css={CardDescriptionStyle}>
                <p>
                  専任チームが初期設定から導入定着までしっかり伴走するサポートプランや、paildをより便利にご利用いただけるオプション機能など、お客様のご要望に合わせて最適なプランをご提案します。
                </p>
              </div>
            </div>
          </div>
          <div css={DescriptionStyle}>
            <p>
              「paild」はカード発行するだけではなく、領収書の回収管理から仕訳まで、経費処理に必要な一連の機能を豊富にご提供しております。
              <br />
              <br css={SPOnlyStyle} />
              初期費用のみでも十分便利にご活用いただけるサービスではございますが、オプションをご利用いただくことで、さらに利便性が高まります。
              <br />
              <br css={SPOnlyStyle} />
              オプションに関わる詳細の料金については、
              <Link to='https://www.paild.io/contact/' css={LinkStyle}>
                お問い合わせフォーム
              </Link>
              よりお気軽にご連絡ください。
            </p>
          </div>
          <div css={ButtonWrapperStyle}>
            <Link to='/request-document/' css={{ width: '100%' }}>
              <PaildButton arrow>資料請求</PaildButton>
            </Link>
          </div>
          <div css={TableWrapperPCStyle}>
            <h3 css={SubTitleStyle}>機能一覧</h3>
            <table css={TablePCStyle} cellSpacing={0}>
              <thead css={TablePCHeadStyle}>
                <tr>
                  <th css={{ width: '60%' }} colSpan={2}></th>
                  <th css={{ width: '20%' }}>基本機能</th>
                  <th css={{ width: '20%' }}>オプション</th>
                </tr>
              </thead>
              {tableContentsWithTitles.map((data, i) => (
                <Fragment key={i}>
                  <thead>
                    <tr css={TableSubHeader}>
                      <th colSpan={4} align='left'>
                        <span css={{ paddingLeft: '12px' }}>{data.title}</span>
                      </th>
                    </tr>
                  </thead>
                  {data.contents.map((content, ii) => (
                    <tbody key={`${i}-${ii}`} css={TableBodyStyle}>
                      <tr>
                        <td css={{ width: '20%' }} />
                        <td
                          css={{ width: '40%', whiteSpace: 'pre-wrap' }}
                          align='left'
                        >
                          {content.title}
                        </td>
                        <td align='center'>
                          <FunctionStatusChip {...content.basic} />
                        </td>
                        <td align='center'>
                          <FunctionStatusChip {...content.option} />
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </Fragment>
              ))}
            </table>
          </div>
          <div css={TableWrapperSPStyle}>
            <h3 css={SubTitleStyle}>基本機能</h3>
            <table css={TableSPStyle}>
              {tableContentsWithTitles.map((data, i) => (
                <Fragment key={i}>
                  <thead>
                    <tr css={TableSubHeader}>
                      <th colSpan={4} align='center'>
                        <span css={{ paddingLeft: '12px' }}>{data.title}</span>
                      </th>
                    </tr>
                  </thead>
                  {data.contents.map((content, ii) => (
                    <tbody key={`${i}-${ii}`} css={TableBodyStyle}>
                      <tr>
                        <td css={{ width: '20%' }} align='center'>
                          <FunctionStatusChip {...content.basic} />
                        </td>
                        <td
                          css={{ width: '80%', whiteSpace: 'pre-wrap' }}
                          align='left'
                        >
                          {content.title}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </Fragment>
              ))}
            </table>
            <h3 css={SubTitleStyle}>オプション</h3>
            <table css={TableSPStyle}>
              {tableContentsWithTitles.map((data, i) => (
                <Fragment key={i}>
                  <thead>
                    <tr css={TableSubHeader}>
                      <th colSpan={4} align='center'>
                        <span css={{ paddingLeft: '12px' }}>{data.title}</span>
                      </th>
                    </tr>
                  </thead>
                  {data.contents.map((content, ii) => (
                    <tbody key={`${i}-${ii}`} css={TableBodyStyle}>
                      <tr>
                        <td css={{ width: '20%' }} align='center'>
                          <FunctionStatusChip {...content.option} />
                        </td>
                        <td
                          css={{ width: '80%', whiteSpace: 'pre-wrap' }}
                          align='left'
                        >
                          {content.title}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </Fragment>
              ))}
            </table>
          </div>
          <div css={ButtonWrapperStyle}>
            <Link to='/request-document/' css={{ width: '100%' }}>
              <PaildButton arrow>資料請求</PaildButton>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PricePage;

type FunctionStatusChipProps = {
  status: 'ok' | 'middle' | 'bad' | 'none';
  caption?: string;
};

const FunctionStatusChip: FC<FunctionStatusChipProps> = ({
  status,
  caption,
}) => {
  const switchStatus = () => {
    switch (status) {
      case 'ok':
        return '○';
      case 'middle':
        return '△';
      case 'bad':
        return '×';
      case 'none':
        return 'ー';
    }
  };

  return (
    <div css={FunctionStatusChipStyle(status)}>
      {switchStatus()}
      {caption && <span>{caption}</span>}
    </div>
  );
};

const TopSectionBackgroundStyle = css({
  position: 'absolute',
  top: -200,
  left: 0,
  width: '100%',
  height: '100%',
  overflow: 'hidden',

  '@media(max-width: 1079px)': {
    display: 'none',
  },
});

const ArticleListHeadingStyle = css({
  width: 'calc(100% - 2px)',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',

  '@media(max-width: 1079px)': {
    minHeight: 200,
  },
});

const ArticleListHeadingWrapperStyle = css({
  position: 'relative',
  width: 1800,
  borderRadius: 16,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 40,
});

const TextWrapperStyle = css({
  width: '100%',
  maxWidth: 1200,
  zIndex: 2,
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  padding: '80px 28px 0',

  '@media(max-width: 1079px)': {
    maxWidth: 576,
    height: 'auto',
    padding: '40px 14px 0',
  },
});

const CategoryStyle = css({
  fontSize: 16,
  fontWeight: 500,
  lineHeight: 1,
  color: colors.primary,
  textTransform: 'capitalize',

  '@media(max-width: 1079px)': {
    fontSize: 14,
  },
});

const TitleStyle = css({
  fontSize: 48,
  fontWeight: 700,
  lineHeight: 1,
  color: colors.black,
  marginBottom: 36,

  '@media(max-width: 1079px)': {
    fontSize: 28,
    marginBottom: 6,
  },
});

const CardWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 40,
  width: '100%',
  padding: '20px 28px',
  flexWrap: 'wrap',

  '> div': {
    width: '400px',
    height: '196px',
  },

  '@media(max-width: 1079px)': {
    justifyContent: 'normal',
    flexDirection: 'column',
    '> div': {
      width: '100%',
      height: 'auto',
    },
  },
});

const CardStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  maxWidth: 340,
  background: colors.white,
  boxShadow: '0px 0px 60px 0px rgba(0, 0, 0, 0.25)',
});

const CardTitleStyle = css({
  display: 'grid',
  placeItems: 'center',
  height: '60px',
  width: '100%',
  backgroundColor: colors.primary,
  color: colors.white,
});

const CardDescriptionStyle = css({
  padding: '20px 30px',
  fontSize: 14,
});

const PriceStyle = css({
  textAlign: 'center',
  fontSize: 24,
  fontWeight: 700,
  color: colors.primary,
  marginBottom: 10,

  span: {
    fontSize: 16,
    fontWeight: 500,
    marginLeft: '10px',
  },
});

const DescriptionStyle = css({
  fontSize: 16,
  lineHeight: 2,
  textAlign: 'center',
  width: '1022px',

  '@media(max-width: 1079px)': {
    width: '100%',
    fontSize: 14,
    padding: '16px',
    maxWidth: '360px',
  },
});

const SPOnlyStyle = css({
  '@media(min-width: 1080px)': {
    display: 'none',
  },
});

const LinkStyle = css({
  textDecoration: 'underline',
});

const ButtonWrapperStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  maxWidth: 340,
  padding: '16px',
});

const SubTitleStyle = css({
  fontSize: 40,
  fontWeight: 700,
  lineHeight: 1,
  color: colors.black,
  padding: '0 12px 12px 12px',
  borderBottom: `3px solid ${colors.primary}`,
  marginTop: '36px',

  '@media(max-width: 1079px)': {
    fontSize: 28,
  },
});

const TableWrapperPCStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',

  '@media(max-width: 1079px)': {
    display: 'none',
  },
});

const TablePCStyle = css({
  width: '860px',
  border: 'none',
  borderCollapse: 'collapse',
  marginBottom: '36px',
});

const TableSubHeader = css({
  width: '100%',
  height: '40px',
  backgroundColor: colors.secondary,
  border: 'none',
});

const FunctionStatusChipStyle = (status: 'ok' | 'middle' | 'bad' | 'none') =>
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: status === 'ok' ? colors.primary : colors.disabled,
    position: 'relative',

    span: {
      fontSize: '10px',
      color: colors.black,
      position: 'absolute',
      top: '16px',
      left: '50%',
      transform: 'translateX(-50%)',
      whiteSpace: 'nowrap',

      '@media(max-width: 1079px)': {
        fontSize: '8px',
      },
    },
  });

const TableBodyStyle = css({
  height: '40px',

  '@media(min-width: 1080px)': {
    ':hover': {
      backgroundColor: colors.tertiary,
    },
  },
});

const TablePCHeadStyle = css({
  height: '100px',
  position: 'sticky',
  top: 80,
  backgroundColor: colors.white,
  zIndex: 1,

  '@media(max-width: 1120px)': {
    top: 56,
  },

  '> tr': {
    th: {
      verticalAlign: 'bottom',
      paddingBottom: '10px',
    },

    ':after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      borderBottom: `2px solid ${colors.secondary}`,
      width: '100%',
      height: '100%',
      zIndex: -1,
    },
  },
});

const TableWrapperSPStyle = css({
  display: 'none',

  '@media(max-width: 1079px)': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0 12px',
    maxWidth: '335px',
    width: '100%',
  },
});

const TableSPStyle = css({
  width: '100%',
  border: 'none',
  borderCollapse: 'collapse',
  margin: '24px 0',
});
